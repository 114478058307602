module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@1.15.0_@babel+core@7.21.3_gatsby-plugin-sharp@3.15.0_gatsby-source-filesy_d25r5npe44fezpklilouj42puy/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@4.2.0_gatsby-plugin-sharp@3.15.0_gatsby@3.15.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@3.2.0_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"icon":false,"maintainCase":false,"removeAccents":true,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.15.0_gatsby@3.15.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/favicon.png","name":"Aviation Research & Consulting","short_name":"Aviation Research & Consulting","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2785f7a4aed1b382aad979dcc31772bc"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@5.15.0_gatsby@3.15.0_netlify-cms-app@2.9.5_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
