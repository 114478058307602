// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-book-page-tsx": () => import("./../../../src/templates/book-page.tsx" /* webpackChunkName: "component---src-templates-book-page-tsx" */),
  "component---src-templates-books-page-tsx": () => import("./../../../src/templates/books-page.tsx" /* webpackChunkName: "component---src-templates-books-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-research-paper-tsx": () => import("./../../../src/templates/research-paper.tsx" /* webpackChunkName: "component---src-templates-research-paper-tsx" */),
  "component---src-templates-research-papers-page-tsx": () => import("./../../../src/templates/research-papers-page.tsx" /* webpackChunkName: "component---src-templates-research-papers-page-tsx" */),
  "component---src-templates-simple-page-tsx": () => import("./../../../src/templates/simple-page.tsx" /* webpackChunkName: "component---src-templates-simple-page-tsx" */)
}

